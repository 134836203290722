import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

import Table from './table';


function renderCodeSnippet(tables, id) {
  for (const table of tables) {
    if (table.key && table.key === id) {
      return (
        <Table key={`table|${id}`} {...table} />
      );
    }
  }

  return null;
}

function d2cOptions(references, tables) {
  return {
    renderMark: {
      [MARKS.CODE]: (data) => {
        const output = [];

        if ((data.indexOf('|') !== -1 || data.indexOf(':') !== -1) && Array.isArray(tables)) {
          const items = data.split('|');

          for (const item of items) {
            const [ , id ] = item.split(':');

            if (id !== undefined) {
              output.push(renderCodeSnippet(tables, id));
            }
          }
        }

        if (output.length > 0) {
          return output;
        }

        return null;
      }
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: ({ data }) => {
        if (
          !data ||
          !data.target ||
          !data.target.sys ||
          !data.target.sys.id ||
          data.target.sys.linkType !== 'Asset'
        ) {
          return null;
        }

        const ref = references.find(ref => data.target.sys.id === ref.cid);

        const alt = ref.alt || '';
        const href = ref.href || undefined;
        let src = undefined;

        if (ref.fixed && ref.fixed.src) {
          src = ref.fixed.src;
        }

        if (!src) {
          return null;
        }

        let className = 'responsiveImgBlock';

        if (src.indexOf('-ar.') !== -1) {
          className += ' right';
        } else if (src.indexOf('-al.') !== -1) {
          className += ' left';
        }

        if (href) {
          return (
            <a href={href}>
              <img className={className} alt={alt} src={src} />
            </a>
          );
        }

        return (
          <img className={className} alt={alt} src={src} />
        );
      },
      [BLOCKS.PARAGRAPH]: ({ content }, children) => {
        if (
          Array.isArray(content) &&
          content.length === 1 &&
          content[0].hasOwnProperty('marks') &&
          Array.isArray(content[0].marks) &&
          content[0].marks.findIndex(m => m.type === 'code') !== -1
        ) {
          return children;
        }

        return <p>{children}</p>
      }
    }
  };
};

export default function ContentfulRichText({ raw, references, tables }) {
  return (
    <div className="cRichText">
      {documentToReactComponents(JSON.parse(raw), d2cOptions(references, tables))}
    </div>
  )
};